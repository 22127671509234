const personEmail = () => ({
  email: '',
  isValid: 'Nuevo',
})

const personAddress = () => ({
  addressEnabled: true,
  addressIsDefault: true,
  addressIsForBilling: true,
  addressIsForShipping: true,
  addressLine: 'Bogotá, D.C. (BOG)',
  addressName: 'Principal',
  addressNeighborhood: '',
  addressPhone: '',
  addressPostalCode: '',
  cityCode: '11001',
  cityName: 'Bogotá, D.C. (BOG)',
  countryCode: 'CO',
  countryName: 'Colombia',
  regionCode: '11',
  regionName: 'Bogotá',
})

export default () => ({
  company: {
    companyIdentification: '',
    companyIdentificationCheck: '',
    companyName: '',
    identificationTypeId: '31',
    identificationTypeCode: 'NIT',
    identificationTypeName: 'NIT',
  },
  email: '',
  firstName: '',
  ipAddress: '',
  lastName: '',
  person: {
    address: [personAddress()],
    personContributorKind: 'RV',
    fileRut: '',
    personEmails: [],
    personIsCustomer: true,
    personIsSimpleRegime: false
  },
  phone: '',
  seller: '',
  source: '',
  userName: '',
})
export { personAddress, personEmail }
